import { DarkModeSwitcher } from 'ssap-ui-kit';
import classNames from 'classnames';
import goToTop from 'helpers/goToTop';
import { useProtocolDataContext } from 'libs/protocol-data-provider';
import React from 'react';
import { useIntl } from 'react-intl';
import { LOGO, moreMenuItems } from 'ui-config';
import LangSwitcher from '../basic/LangSwitcher';
import Link from '../basic/Link';
import staticStyles from './style';

interface FooterProps {
  inside?: boolean;
}

export default function Footer({ inside }: FooterProps) {
  const intl = useIntl();
  const { currentMarketData } = useProtocolDataContext();
  return (
    <footer className={classNames('Footer _pdv-4px _pdh-16px', { Footer__inside: inside })}>
      <Link className="_mgr-at _mgv-at _mgl-12px" to="/markets" onClick={() => goToTop()}>
        <img className="_h-24px" src={LOGO} alt="SSAP" />
      </Link>
      <div className="_fs-300">
        {moreMenuItems.map((link, index) => (
          <Link
            key={index}
            className={classNames(
              '_cl-white _cl-base-100-hover _pdh-16px _pdv-8px ',
              link.isVisible && !link.isVisible(currentMarketData) ? '_dp-n' : ''
            )}
            to={link.link}
            inNewWindow={link.absolute}
            absolute={link.absolute}
          >
            <p>{intl.formatMessage(link.title)}</p>
          </Link>
        ))}
        {/* <a
          href="https://killswitchofficial.gitbook.io/killswitch"
          target="_blank"
          className="_pdh-16px _pdv-8px "
          rel="noreferrer"
        >
          FAQ
        </a>
        <a
          href="https://killswitchofficial.gitbook.io/killswitch"
          target="_blank"
          className="_pdh-16px _pdv-8px "
          rel="noreferrer"
        >
          Developers
        </a>
        <a
          href="https://killswitchofficial.gitbook.io/killswitch"
          target="_blank"
          className="_pdh-16px _pdv-8px "
          rel="noreferrer"
        >
          Security
        </a> */}
      </div>
      <div className="_mgl-4px _mgr-24px _cl-white">|</div>
      <DarkModeSwitcher />
      <LangSwitcher />
      <style jsx={true} global={true}>
        {staticStyles}
      </style>
    </footer>
  );
}
