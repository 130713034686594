import React from 'react';
import { useIntl } from 'react-intl';
import { CompactNumber } from '../../../../components/basic/CompactNumber';
import messages from './messages';
import staticStyles from './style';

interface TotalMarketsSizeProps {
  value: number;
}

export default function TotalMarketsSize({ value }: TotalMarketsSizeProps) {
  const intl = useIntl();

  return (
    <div className="TotalMarketsSize">
      <p className="_cl-text-default-2 _mgbt-12px">{intl.formatMessage(messages.title)}</p>
      <h2 className="_cl-text-primary-1">
        ${' '}
        {value < 100000000000 ? (
          intl.formatNumber(value, {
            maximumFractionDigits: 2,
            minimumFractionDigits: 2,
          })
        ) : (
          <CompactNumber value={value} maximumFractionDigits={2} minimumFractionDigits={2} />
        )}
      </h2>

      <style jsx={true}>{staticStyles}</style>
    </div>
  );
}
