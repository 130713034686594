import { ScrollBar } from 'ssap-ui-kit';
import classNames from 'classnames';
import React, { ReactNode } from 'react';

interface CustomScrollProps {
  children: ReactNode;
  className?: string;
  onUpdate?: (value: any) => void;
}

export default function CustomScroll({ children, className, onUpdate }: CustomScrollProps) {
  return (
    <>
      <ScrollBar className={classNames('CustomScroll', className)} onUpdate={onUpdate}>
        {children}
      </ScrollBar>

      <style jsx={true} global={true}>{`
        .CustomScroll > div {
          &:last-of-type {
            div {
              background-color: var(--scrollbar-thumb-background) !important;
            }
          }
        }
      `}</style>
    </>
  );
}
