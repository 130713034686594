import messages from './messages';

import ssapIcon from '../../../../images/ssap.svg';
import stkSSAPIcon from '../../../../images/stkSSAP.svg';

export interface Asset {
  symbol: string;
  icon: string;
  value: number;
  address: string;
}

export const DELEGATED_ASSETS = [
  {
    symbol: 'SSAP',
    icon: ssapIcon,
  },
  {
    symbol: 'stkSSAP',
    icon: stkSSAPIcon,
  },
];

export enum DELEGATION_PARAM_TYPES {
  VOTING = '0',
  PROPOSITION_POWER = '1',
}

export const delegationTypes = [
  {
    value: DELEGATION_PARAM_TYPES.VOTING,
    label: messages.delegationVotes,
  },
  {
    value: DELEGATION_PARAM_TYPES.PROPOSITION_POWER,
    label: messages.delegationProposalPower,
  },
];
