import { Navigation } from '../../components/menu/navigation';
import { isFeatureEnabled } from '../../helpers/config/markets-and-network-config';
import messages from './messages';

export const moreMenuItems: Navigation[] = [
  {
    link: '/faucet',
    title: messages.faucet,
    isVisible: isFeatureEnabled.faucet,
  },
  {
    link: 'https://killswitchofficial.gitbook.io/killswitch',
    title: messages.killSwitchProtocol,
    absolute: true,
  },
  {
    link: 'https://killswitchofficial.gitbook.io/killswitch',
    title: messages.faq,
    absolute: true,
  },
  {
    link: 'https://killswitchofficial.gitbook.io/killswitch',
    title: messages.developers,
    absolute: true,
  },
  {
    link: 'https://killswitchofficial.gitbook.io/killswitch',
    title: messages.security,
    absolute: true,
  },
];

export const moreMenuExtraItems: Navigation[] = [];

export const moreMenuMobileOnlyItems: Navigation[] = [];
