import { ChainId } from '@ssap/contract-helpers';
import { MarketDataType } from '../../helpers/config/types';
import * as logos from './images';

export enum CustomMarket {
  // proto_kovan = 'proto_kovan',
  // proto_mainnet = 'proto_mainnet',
  // proto_avalanche = 'proto_avalanche',
  // proto_matic = 'proto_matic',
  // proto_mumbai = 'proto_mumbai',
  // amm_kovan = 'amm_kovan',
  // amm_mainnet = 'amm_mainnet',
  // proto_fuji = 'proto_fuji',
  // proto_rei_testnet = 'proto_rei_testnet',
  proto_emerald_testnet = 'proto_emerald_testnet',
  proto_emerald = 'proto_emerald',
  proto_clv = 'proto_clv',
}

export const marketsData: { [key in keyof typeof CustomMarket]: MarketDataType } = {
  // [CustomMarket.proto_kovan]: {
  //   chainId: ChainId.kovan,
  //   logo: logos.ssapMarketV2,
  //   activeLogo: logos.ssapMarketV2,
  //   aTokenPrefix: 'A',
  //   enabledFeatures: {
  //     faucet: true,
  //     governance: true,
  //     staking: true,
  //     incentives: true,
  //   },
  //   addresses: {
  //     LENDING_POOL_ADDRESS_PROVIDER: '0x88757f2f99175387ab4c6a4b3067c77a695b0349'.toLowerCase(),
  //     LENDING_POOL: '0xE0fBa4Fc209b4948668006B2bE61711b7f465bAe',
  //     WETH_GATEWAY: '0xA61ca04DF33B72b235a8A28CfB535bb7A5271B70',
  //     FAUCET: '0x600103d518cC5E8f3319D532eB4e5C268D32e604',
  //   },
  // },
  // [CustomMarket.proto_mainnet]: {
  //   chainId: ChainId.mainnet,
  //   logo: logos.ssapMarketV2,
  //   activeLogo: logos.ssapMarketV2,
  //   aTokenPrefix: 'A',
  //   enabledFeatures: {
  //     governance: true,
  //     staking: true,
  //     liquiditySwap: true,
  //     collateralRepay: true,
  //     incentives: true,
  //   },
  //   addresses: {
  //     LENDING_POOL_ADDRESS_PROVIDER: '0xB53C1a33016B2DC2fF3653530bfF1848a515c8c5'.toLowerCase(),
  //     LENDING_POOL: '0x7d2768dE32b0b80b7a3454c06BdAc94A69DDc7A9',
  //     WETH_GATEWAY: '0xcc9a0B7c43DC2a5F023Bb9b738E45B0Ef6B06E04',
  //     REPAY_WITH_COLLATERAL_ADAPTER: '0x498c5431eb517101582988fbb36431ddaac8f4b1',
  //     SWAP_COLLATERAL_ADAPTER: '0x135896DE8421be2ec868E0b811006171D9df802A',
  //   },
  // },
  // [CustomMarket.amm_kovan]: {
  //   chainId: ChainId.kovan,
  //   logo: logos.ammLogo,
  //   activeLogo: logos.ammActiveLogo,
  //   aTokenPrefix: 'AAMM',
  //   addresses: {
  //     LENDING_POOL_ADDRESS_PROVIDER: '0x67FB118A780fD740C8936511947cC4bE7bb7730c'.toLowerCase(),
  //     LENDING_POOL: '0x762E2a3BBe729240ea44D31D5a81EAB44d34ef01',
  //     WETH_GATEWAY: '0xA61ca04DF33B72b235a8A28CfB535bb7A5271B70',
  //     FAUCET: '0x600103d518cC5E8f3319D532eB4e5C268D32e604',
  //   },
  // },
  // [CustomMarket.amm_mainnet]: {
  //   chainId: ChainId.mainnet,
  //   logo: logos.ammLogo,
  //   activeLogo: logos.ammActiveLogo,
  //   aTokenPrefix: 'AAMM',
  //   addresses: {
  //     LENDING_POOL_ADDRESS_PROVIDER: '0xacc030ef66f9dfeae9cbb0cd1b25654b82cfa8d5'.toLowerCase(),
  //     LENDING_POOL: '0x7937d4799803fbbe595ed57278bc4ca21f3bffcb',
  //     WETH_GATEWAY: '0xcc9a0B7c43DC2a5F023Bb9b738E45B0Ef6B06E04',
  //   },
  // },
  // [CustomMarket.proto_mumbai]: {
  //   chainId: ChainId.mumbai,
  //   logo: logos.ssapMarket,
  //   activeLogo: logos.ssapMarket,
  //   subLogo: logos.polygon,
  //   aTokenPrefix: 'AM',
  //   enabledFeatures: {
  //     incentives: true,
  //     faucet: true,
  //   },
  //   addresses: {
  //     LENDING_POOL_ADDRESS_PROVIDER: '0x178113104fEcbcD7fF8669a0150721e231F0FD4B'.toLowerCase(),
  //     LENDING_POOL: '0x9198F13B08E299d85E096929fA9781A1E3d5d827',
  //     WETH_GATEWAY: '0xee9eE614Ad26963bEc1Bec0D2c92879ae1F209fA',
  //     FAUCET: '0x0b3C23243106A69449e79C14c58BB49E358f9B10',
  //   },
  // },
  // [CustomMarket.proto_matic]: {
  //   chainId: ChainId.polygon,
  //   logo: logos.ssapMarket,
  //   activeLogo: logos.ssapMarket,
  //   subLogo: logos.polygon,
  //   aTokenPrefix: 'AM',
  //   enabledFeatures: {
  //     liquiditySwap: true,
  //     incentives: true,
  //   },
  //   addresses: {
  //     LENDING_POOL_ADDRESS_PROVIDER: '0xd05e3E715d945B59290df0ae8eF85c1BdB684744'.toLowerCase(),
  //     LENDING_POOL: '0x8dFf5E27EA6b7AC08EbFdf9eB090F32ee9a30fcf',
  //     WETH_GATEWAY: '0xbEadf48d62aCC944a06EEaE0A9054A90E5A7dc97',
  //     SWAP_COLLATERAL_ADAPTER: '0x35784a624D4FfBC3594f4d16fA3801FeF063241c',
  //   },
  // },
  // [CustomMarket.proto_fuji]: {
  //   chainId: ChainId.fuji,
  //   logo: logos.ssapMarket,
  //   activeLogo: logos.ssapMarket,
  //   subLogo: logos.avalanche,
  //   aTokenPrefix: 'AAVA',
  //   enabledFeatures: {
  //     faucet: true,
  //     incentives: true,
  //   },
  //   addresses: {
  //     LENDING_POOL_ADDRESS_PROVIDER: '0x7fdC1FdF79BE3309bf82f4abdAD9f111A6590C0f'.toLowerCase(),
  //     LENDING_POOL: '0x76cc67FF2CC77821A70ED14321111Ce381C2594D',
  //     WETH_GATEWAY: '0x1648C14DbB6ccdd5846969cE23DeEC4C66a03335',
  //     FAUCET: '0x90E5BAc5A98fff59617080848959f44eACB4Cd7B',
  //   },
  // },
  // [CustomMarket.proto_avalanche]: {
  //   chainId: ChainId.avalanche,
  //   logo: logos.ssapMarket,
  //   activeLogo: logos.ssapMarket,
  //   subLogo: logos.avalanche,
  //   aTokenPrefix: 'AV',
  //   enabledFeatures: {
  //     liquiditySwap: true,
  //     incentives: true,
  //   },
  //   addresses: {
  //     LENDING_POOL_ADDRESS_PROVIDER: '0xb6A86025F0FE1862B372cb0ca18CE3EDe02A318f'.toLowerCase(),
  //     LENDING_POOL: '0x4F01AeD16D97E3aB5ab2B501154DC9bb0F1A5A2C',
  //     WETH_GATEWAY: '0x8a47F74d1eE0e2edEB4F3A7e64EF3bD8e11D27C8',
  //     SWAP_COLLATERAL_ADAPTER: '0x2EcF2a2e74B19Aab2a62312167aFF4B78E93B6C5',
  //   },
  // },
  // [CustomMarket.proto_rei_testnet]: {
  //   chainId: ChainId.rei_testnet,
  //   logo: logos.ssapMarket,
  //   activeLogo: logos.ssapMarket,
  //   // subLogo: logos.emerald,
  //   subLogo: logos.rei,
  //   aTokenPrefix: 'A',
  //   enabledFeatures: {
  //     faucet: true,
  //     governance: true,
  //     staking: true,
  //     incentives: true,
  //   },
  //   addresses: {
  //     LENDING_POOL_ADDRESS_PROVIDER: '0xd9bDEd5e2667a73D199B7e36c75fb09122636D23'.toLowerCase(),
  //     LENDING_POOL: '0x57c5D9a5082e1Af221227F2044F93eE183418b77',
  //     WETH_GATEWAY: '0x5C5A2fC751deCda418DAe75B86F599f2B6798b08',
  //     FAUCET: '0x207e4d676C2a3E6179CDba6956E63C48783f8222',
  //   },
  // },
  [CustomMarket.proto_emerald]: {
    chainId: ChainId.emerald,
    logo: logos.ssapMarket,
    activeLogo: logos.ssapMarket,
    subLogo: logos.emerald,
    aTokenPrefix: 'ss',
    enabledFeatures: {
      faucet: false,
      governance: false,
      staking: false,
      incentives: false,
    },
    addresses: {
      LENDING_POOL_ADDRESS_PROVIDER: '0x1cC83F611381A7Da44Cd5f6c36A97a514E588CF8'.toLowerCase(),
      LENDING_POOL: '0x8C9a21135Dd62e45ddCb1eaE4047c2320329913d',
      WETH_GATEWAY: '0x3C24492d073f59A74498dC5eEa8ffe5A00F00260',
    },
  },
  [CustomMarket.proto_emerald_testnet]: {
    chainId: ChainId.emerald_testnet,
    logo: logos.ssapMarket,
    activeLogo: logos.ssapMarket,
    // subLogo: logos.emerald,
    subLogo: logos.emerald,
    aTokenPrefix: 'ss',
    enabledFeatures: {
      faucet: true,
      governance: false,
      staking: false,
      incentives: false,
    },
    addresses: {
      LENDING_POOL_ADDRESS_PROVIDER: '0x90287d48bbcf7157eb437b0aecbe011251dbe281'.toLowerCase(),
      LENDING_POOL: '0xf20fFffB00BEb64808f31Fc8fFAA60a3907ADF74',
      WETH_GATEWAY: '0x3429D39DD2553B03bF9372bb94cb2BBC708fFc2a',
      FAUCET: '0x3d86B3Ea3F35E7262788fD66a3eD3D964F32201e',
    },
  },
  [CustomMarket.proto_clv]: {
    chainId: ChainId.clv,
    logo: logos.ssapMarket,
    activeLogo: logos.ssapMarket,
    subLogo: logos.clv,
    aTokenPrefix: 'ss',
    enabledFeatures: {
      faucet: false,
      governance: false,
      staking: false,
      incentives: false,
    },
    addresses: {
      LENDING_POOL_ADDRESS_PROVIDER: '0x634C85D9ed6A83375551F1e23368FF0F0e9b073F'.toLowerCase(),
      LENDING_POOL: '0xC6A236847C0EEf6446E2074069E58b0753076A19',
      WETH_GATEWAY: '0x707d0a20f92a99fd96cdd6aB365D81Ce9149A304',
    },
  },
} as const;
