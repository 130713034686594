import { SocialIcon } from 'ssap-ui-kit';
import React from 'react';
import { IntlShape } from 'react-intl/src/types';
import {
  DashboardLeftTopLine as DashboardLeftTopLineUI,
  DashboardLeftTopLineProps,
} from './DashboardLeftTopLine';
import FormattedTxErrorTextUI from './FormattedTxErrorText';
import logo from './images/ssapLogo.svg';
import LegalBanner from './LegalBanner';
import TopDisclaimerUI from './TopDisclaimer';
import { UnlockWalletExtraText as UnlockWalletExtraTextUI } from './UnlockWalletExtraText';

export const LOGO = logo;

export const socialIcons: SocialIcon[] = [
  // {
  //   url: 'https://github.com/aave/aave-ui',
  //   type: SocialType.Github,
  // },
  // {
  //   url: 'https://discord.gg/7kHKnkDEUf',
  //   type: SocialType.Discord,
  // },
];

export const TopDisclaimer: React.FC = TopDisclaimerUI;
export const BottomDisclaimer: React.FC = LegalBanner;
export const FormattedTxErrorText: React.FC = FormattedTxErrorTextUI;

export const DashboardLeftTopLine: React.FC<DashboardLeftTopLineProps> = DashboardLeftTopLineUI;

export const UnlockWalletExtraText: React.FC<{ intl: IntlShape }> = UnlockWalletExtraTextUI;
